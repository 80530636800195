import { StyledCTAFinLink, Wrapper, Link } from './CTAFinLink.Styled'
import { useSelector } from 'react-redux'

const CTAFinLink = ({ margin, padding, width, link, text }) => {
  const translation = useSelector((s) => s.translation)
  return (
    <Wrapper margin={margin}>
      <StyledCTAFinLink>
        <Link
          padding={padding}
          width={width}
          href={link || 'https://hyprofinance.finlink.de/start/finance_type'}
          target="_blank"
        >
          {text || translation.home.ctaFinLink.text}
        </Link>
      </StyledCTAFinLink>
    </Wrapper>
  )
}

export default CTAFinLink
