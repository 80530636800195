import { useSelector } from 'react-redux'
import Block from '../../UI/Block/Block'
import { Overlay, Headline, Text, Content } from './StepByStep.Styled'
import bg from '../../../assets/image/home/stepByStep/bg.png'

const StepByStep = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <Block height="70vh" backgroundImage={bg} overflow="visible" backgroundPosition={'center top'}>
      <Overlay>
        <Content>
          <Headline>{translation.home.stepByStep.headline}</Headline>
          <Text bold>{translation.home.stepByStep.text1}</Text>
          <Text>{translation.home.stepByStep.text2}</Text>
          <Text>{translation.home.stepByStep.text3}</Text>
        </Content>
      </Overlay>
    </Block>
  )
}

export default StepByStep
