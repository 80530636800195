import { useSelector } from 'react-redux'
import { StyledFooter, Split, Section, CustomLink, Title } from './Footer.Styled'

const Footer = () => {
  const translation = useSelector((s) => s.translation)

  return (
    <StyledFooter>
      <Split>
        <Section>
          <Title text={translation.footer.legal.title} />
          <CustomLink
            newTab
            href={'https://hyprofinance.de/impressum/'}
            text={translation.footer.legal.imprint}
          />
          <CustomLink
            newTab
            href={'https://hyprofinance.de/datenschutz/'}
            text={translation.footer.legal.dataProtection}
          />
          <CustomLink
            newTab
            href={'https://hyprofinance.de/agb/'}
            text={translation.footer.legal.agb}
          />
        </Section>
      </Split>
    </StyledFooter>
  )
}

export default Footer
