import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import styled, { css } from 'styled-components'

function useIsVisible(ref) {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

  useEffect(() => {
    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isIntersecting
}

export const Wrapper = styled.div`
  animation: ${({ animation, duration, isVisible }) =>
    isVisible
      ? css`
          ${duration} ${animation}
        `
      : ''};
`

const ShowIfVisible = ({ children, animation, duration }) => {
  const ref = useRef(null)
  const isVisible = useIsVisible(ref)

  return (
    <Wrapper isVisible={isVisible} duration={duration || '2s '} animation={animation} ref={ref}>
      {children}
    </Wrapper>
  )
}

export default ShowIfVisible
