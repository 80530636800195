import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`

* {
  padding: 0px;
  margin: 0px;
  font-family: "regular";
  font-size: ${({ theme }) => theme.size.md};
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.font.primary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.sm};
  }
}

html,
body {
  scroll-behavior: smooth;
  background-color: ${({ theme }) => theme.color.background};
}

.Toastify {
  * {
  font-size: ${({ theme }) => theme.size.sm};
  }
}

.main-content {
  min-height: 100vh;
}

`

export default GlobalStyles
