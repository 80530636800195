import Layout from '../UI/Layout/Layout'
import { StyledHome } from './Home.Styled'
import Intro from './Intro/Intro'
import Dreams from './Dreams/Dreams'
import StepByStep from './StepByStep/StepByStep'
import Process from './Process/Process'
import Partner from './Partner/Partner'
import Ratings from './Ratings/Ratings'
import Signature from './Signature/Signature'
import People from './People/People'

const Home = () => {
  return (
    <Layout>
      <StyledHome>
        <Intro />
        <Dreams />
        <StepByStep />
        <Process />
        <Partner />
        <Ratings />
        <Signature />
        <People />
      </StyledHome>
    </Layout>
  )
}

export default Home
