import styled from 'styled-components'

export const StyledText = styled.div`
  color: ${({ color, theme }) => color || theme.color.font.primary};
  text-align: ${({ align }) => align || 'left'};
  font-family: ${({ font }) => font || 'regular'};
  margin: ${({ margin }) => margin || '0px'};
  font-size: ${({ size }) => size || 'unset'};
  width: ${({ width }) => width || '100%'};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ size }) => size - 10 || 'unset'};
  }
`
