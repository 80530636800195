import styled from 'styled-components'

export const StyledCTAFinLink = styled.div`
  display: inline-block;
  padding: ${({ padding }) => padding || '25px 50px'};
  /* background-color: ${({ theme }) => theme.color.backgroundRed}; */
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 10px 15px;
  }
`

export const Wrapper = styled.div`
  text-align: center;
  margin: ${({ margin }) => margin || '0px'};
`

export const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const Section = styled.div`
  width: ${({ width }) => width};
`

export const Image = styled.img``

export const Text = styled.div`
  text-align: center;
  font-family: 'bold';
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.md};
  }
`

export const Link = styled.a`
  border-radius: 3px;
  text-decoration: none;
  background-color: rgb(157, 21, 37);
  color: ${({ theme }) => theme.color.font.secondary};
  padding: ${({ padding }) => padding || '10px 15px'};
  font-size: 1.5rem;
  width: ${({ width }) => width || 'auto'};
  display: inline-block;
`
