import { useSelector } from 'react-redux'
import Block from '../../UI/Block/Block'
import { Headline, ProcessSteps, Image } from './Process.Styled'
import img1 from '../../../assets/image/home/process/1.png'
import img2 from '../../../assets/image/home/process/2.png'
import img3 from '../../../assets/image/home/process/3.png'
import img4 from '../../../assets/image/home/process/4.png'
import img5 from '../../../assets/image/home/process/5.png'
import Container from '../../UI/Container/Container'
import CTA from '../CTA/CTA'

const Prozess = () => {
  const translation = useSelector((s) => s.translation)

  return (
    <Block height="auto" padding="100px 0" margin="50px 0 0 0">
      <Container>
        <Headline>{translation.home.process.headline}</Headline>
      </Container>
      <ProcessSteps>
        <Image width="200px" src={img1} />
        <Image width="200px" src={img2} />
        <Image width="200px" src={img3} />
        <Image width="200px" src={img4} />
        <Image width="200px" src={img5} />
      </ProcessSteps>
      <CTA />
    </Block>
  )
}

export default Prozess
