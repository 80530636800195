import styled from 'styled-components'

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.size.lg};
  color: ${({ theme }) => theme.color.font.thirdary};
  font-family: 'bold';
  margin-bottom: 25px;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const Row = styled.div`
  display: flex;
  gap: 50px;
  flex-wrap: nowrap;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
    gap: 25px;
  }
`

export const Rating = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    flex: auto;
  }
`

export const StyledStars = styled.div`
  gap: 5px;
  display: flex;
`

export const Star = styled.img`
  height: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 15px;
  }
`

export const Text = styled.div`
  margin: 15px 0 10px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.md};
  }
`

export const Name = styled.div`
  font-style: italic;
  font-size: ${({ theme }) => theme.size.sm};
  color: ${({ theme }) => theme.color.font.light};
`

export const GlobalRatingImage = styled.img`
  width: 200px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 150px;
  }
`
