import { StyledCTA, Wrapper, SectionWrapper, Section, Image, Text, Link } from './CTA.Styled'
import calendlyImage from '../../../assets/image/home/cta/calendly.svg'
import phoneImage from '../../../assets/image/home/cta/phone.svg'
import { useSelector } from 'react-redux'

const CTA = ({ margin }) => {
  const translation = useSelector((s) => s.translation)
  return (
    <Wrapper margin={margin}>
      <StyledCTA>
        <SectionWrapper>
          <Section width="20%">
            <Link href="tel:030 60 50 80 62">
              <Image src={phoneImage} />
            </Link>
          </Section>
          <Section width="60%">
            <Text>{translation.home.cta.text}</Text>
          </Section>
          <Section width="20%">
            <Link href="https://calendly.com/ahmet-akin?month=2020-04" target="_blank">
              <Image src={calendlyImage} />
            </Link>
          </Section>
        </SectionWrapper>
      </StyledCTA>
    </Wrapper>
  )
}

export default CTA
