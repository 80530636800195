import { StyledHeader, Item, ItemWrapper, Float, CustomLink, Icon } from './Header.Styled'
import logoImage from '../../../../assets/image/logo.png'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Container from '../../Container/Container'

const Header = () => {
  const translation = useSelector((s) => s.translation)
  const [userScrolled, setUserScrolled] = useState(false)

  const handleScroll = () => {
    const result = window.scrollY > 100
    if (result !== userScrolled) {
      setUserScrolled(result)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <StyledHeader>
      <Float userScrolled={userScrolled}>
        <Container>
          <ItemWrapper>
            <Item>
              <Icon src={logoImage} />
            </Item>
            <Item>
              <CustomLink href={'/'} text={translation.header.partner} />
            </Item>
            <Item>
              <CustomLink href={'/products'} text={translation.header.products} />
            </Item>
            <Item>
              <CustomLink href={'/'} text={translation.header.businessFields} />
            </Item>
            <Item>
              <CustomLink href={'/'} text={translation.header.downloads} />
            </Item>
            <Item>
              <CustomLink href={'/'} text={translation.header.career} />
            </Item>
            <Item>
              <CustomLink href={'/'} text={translation.header.contact} />
            </Item>
          </ItemWrapper>
        </Container>
      </Float>
    </StyledHeader>
  )
}

export default Header
