import styled from 'styled-components'

export const Headline = styled.div`
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.font.thirdary};
  font-size: ${({ theme }) => theme.size.lg};
  font-family: 'bold';
`

export const SplitWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 50px 0;
  gap: 50px;
  flex-wrap: wrap;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`

export const Text = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.size.md};
`

export const Split = styled.div`
  flex: 1 1 0px;
`

export const Bulletpoints = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  gap: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-top: 15px;
  }
`

export const Point = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const PointText = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.md};
  }
`

export const Indicator = styled.img`
  height: 30px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 20px;
  }
`
