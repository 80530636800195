import { StyledText } from "./Text.Styled";

const Text = ({ text, size, color, align, font, margin, className, width }) => {
  return (
    <StyledText
      size={size}
      color={color}
      align={align}
      font={font}
      margin={margin}
      className={className}
      width={width}
    >
      {text}
    </StyledText>
  );
};

export default Text;
