import styled from 'styled-components'

export const Overlay = styled.div`
  position: absolute;
  height: 110%;
  background-color: ${({ theme }) => theme.color.backgroundRedTransparent};
  width: fit-content;
  max-width: 600px;
  padding: 30px;
  display: flex;
  align-items: center;
  top: -5%;
  left: 15%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0px;
  }
`
export const Headline = styled.div`
  font-size: ${({ theme }) => theme.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
`
export const Text = styled.div`
  ${({ bold }) => bold && `font-family: 'bold';`}
  color: ${({ theme }) => theme.color.font.secondary};
  margin-top: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.md};
  }
`

export const Content = styled.div``
