import { Banner, Logo, CustomBlock } from './Intro.Styled'
import bg from '../../../assets/image/home/intro/bg.jpg'
import logo from '../../../assets/image/logo.png'
import { useSelector } from 'react-redux'

const Intro = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <CustomBlock height="80vh" backgroundImage={bg} backgroundPosition="center top">
      <Banner>{translation.home.intro.banner}</Banner>
      <Logo src={logo} />
    </CustomBlock>
  )
}

export default Intro
