import styled from 'styled-components'

export const StyledButton = styled.button`
  color: ${({ theme, color }) => color || theme.color.button.font};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.color.button.background};
  padding: 10px 25px;
  margin: ${({ margin }) => margin || '0px'};
  border: none;
  display: inline-block;
  text-align: center;
  font-size: ${({ theme, minimized }) => (minimized ? theme.size.md : theme.size.lg)};
  font-family: 'bold';
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.md};
  }
`

export const StyledButtonAnchor = styled.a`
  color: ${({ theme, color }) => color || theme.color.button.font};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.color.button.background};
  padding: 10px 25px;
  margin: ${({ margin }) => margin || '0px'};
  border: none;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  font-size: ${({ theme, minimized }) => (minimized ? theme.size.md : theme.size.lg)};
  font-family: 'bold';
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.md};
  }
`
