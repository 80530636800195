import { useEffect } from 'react'

const Widget = () => {
  const code = `
  <div id="pewl"></div>  
  <link rel="stylesheet" type="text/css" href="https://www.provenexpert.com/css/widget_landing.css" media="screen,print">
  `

  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src =
      'https://www.provenexpert.com/widget/landing_hypro-finance-gmbh.js?feedback=0&avatar=0&competence=1&style=white'
    document.head.appendChild(script)
  })

  return <div className="content" dangerouslySetInnerHTML={{ __html: code }}></div>
}

export default Widget
