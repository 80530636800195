import styled from 'styled-components'

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.size.lg};
  color: ${({ theme }) => theme.color.font.thirdary};
  font-family: 'bold';
  text-align: center;
`

export const ProcessSteps = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 15px;
  margin: 50px 0;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
    padding: 0 15px;
  }
`

export const Image = styled.img`
  width: 200px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(50% - 7.5px);
    max-width: 400px;
  }
`
