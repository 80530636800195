import { forwardRef } from 'react'
import { StyledBlock, CustomContainer } from './Block.Styled'

const Block = (
  {
    height,
    margin,
    padding,
    container,
    children,
    sticky,
    overflow,
    backgroundImage,
    backgroundPosition,
    className,
  },
  ref,
) => {
  return (
    <StyledBlock
      sticky={sticky}
      height={height}
      margin={margin}
      padding={padding}
      overflow={overflow}
      backgroundImage={backgroundImage}
      backgroundPosition={backgroundPosition}
      ref={ref}
      className={className}
    >
      {container ? <CustomContainer>{children}</CustomContainer> : children}
    </StyledBlock>
  )
}

export default forwardRef(Block)
