import { Text, SignatureImage, Content, CustomBlock } from './Signature.Styled'
import { useSelector } from 'react-redux'
import bg from '../../../assets/image/home/signature/bg.png'
import signatureImage from '../../../assets/image/home/signature/signature.png'

const Signature = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <CustomBlock padding="100px 0px" height="60vh" backgroundImage={bg}>
      <Content>
        <Text>{translation.home.signature.text}</Text>
        <SignatureImage src={signatureImage} />
      </Content>
    </CustomBlock>
  )
}

export default Signature
