import {
  Headline,
  Subheadline,
  PeopleList,
  Person,
  Name,
  Title,
  Mail,
  Mobile,
  Image,
  Highlight,
  Link,
  YoutubeImage,
  CTAHeadline,
} from './People.Styled'
import Block from '../../UI/Block/Block'
import { useSelector } from 'react-redux'
import person1 from '../../../assets/image/home/people/1.png'
import person2 from '../../../assets/image/home/people/2.png'
import person3 from '../../../assets/image/home/people/3.png'
import person4 from '../../../assets/image/home/people/4.png'
import Container from '../../UI/Container/Container'
import { getUUID } from '../../../utility'
import CTAFinLink from '../CTAFinLink/CTAFinLink'
import youtubeImage from '../../../assets/image/home/people/youtube.svg'
import ShowIfVisible from '../../animations/ShowIfVisible'
import slideUp from '../../animations/slideUp'

const People = () => {
  const translation = useSelector((s) => s.translation)
  const images = [person1, person2, person3, person4]
  return (
    <Block padding="50px 0px" height="auto">
      <Container>
        <CTAHeadline>{translation.home.people.ctaHeadline}</CTAHeadline>
        <CTAFinLink
          margin="0 0 50px 0"
          padding="15px"
          link={'https://www.youtube.com/watch?v=Qkwe37p_g3s'}
          text={translation.home.people.videoCTA}
        />
      </Container>
      <Container>
        <Headline>{translation.home.people.headline}</Headline>
        <Subheadline>{translation.home.people.subheadline}</Subheadline>
      </Container>
      <PeopleList>
        {translation.home.people.list.map((p, i) => (
          <Person key={getUUID()}>
            <Image src={images[i]} />
            <Name>{p.name}</Name>
            <Title>{p.title}</Title>
            <Mail href={`mailto:${p.mail}`}>
              <Highlight>{p.mail}</Highlight>
            </Mail>
            <Mobile href={`tel:${p.Mobile}`}>
              {translation.home.people.mobile}
              <Highlight>{p.mobile}</Highlight>
            </Mobile>
            {p.youtube && (
              <Link href={p.youtube} target="_blank">
                <YoutubeImage src={youtubeImage} />
              </Link>
            )}
          </Person>
        ))}
      </PeopleList>
      <ShowIfVisible animation={slideUp} duration="1s">
        <CTAFinLink
          padding="20px 30px"
          margin="0 0 50px 0"
          text={translation.home.people.secondCTA}
        />
      </ShowIfVisible>
    </Block>
  )
}

export default People
