import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyle from './Global.Styled'
import { ThemeProvider } from 'styled-components'
import getTheme from './shared-resources/theme'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTheme } from './reducer/action/theme'
import Home from './components/Home/Home'
import './global.scss'

function App() {
  const theme = useSelector((s) => s.theme)
  const dispatch = useDispatch()

  useEffect(() => {
    const pullOriginTheme = async () => {
      dispatch(setTheme(getTheme()))
    }
    if (!theme) {
      pullOriginTheme()
    }
  })

  if (!theme) return <></>

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Routes>
            <Route index element={<Home />} />
            <Route path={'*'} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </div>
  )
}

export default App
