import styled from 'styled-components'
import Block from '../../UI/Block/Block'

export const Banner = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.backgroundRedTransparent};
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.size.xlg};
  line-height: 3rem;
  padding: 25px 0px 20px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.lg};
    text-align: center;
  }
`

export const Logo = styled.img`
  position: absolute;
  top: 75px;
  right: 75px;
  height: 150px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    /* height: 100px; */
    top: 25px;
    right: 25px;
    width: 100px;
    height: auto;
  }
`

export const CustomBlock = styled(Block)`
  background-position: center -50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-position: center center;
    /* background-size: auto 100%; */
  }
`
