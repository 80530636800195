import styled from 'styled-components'
import Container from '../Container/Container'

export const StyledBlock = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  height: ${({ theme, height }) => height || `calc(100vh - ${theme.headerHeight})`};
  margin: ${({ margin }) => margin || '0 0 0 0'};
  padding: ${({ padding }) => padding || '0 0 0 0'};
  overflow: ${({ overflow }) => overflow || 'hidden'};
  position: ${({ sticky }) => (sticky ? 'sticky' : 'relative')};
  top: ${({ theme, sticky }) => (sticky ? theme.headerHeight : '0px')};
  ${({ backgroundImage, backgroundPosition }) =>
    backgroundImage &&
    `
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: ${backgroundPosition ? backgroundPosition : 'center center'};
  background-repeat: no-repeat;
  `};
`

export const CustomContainer = styled(Container)`
  height: 100%;
`
