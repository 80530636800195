import { Headline, Row, Rating, StyledStars, Star, Text, Name } from './Ratings.Styled'
import Block from '../../UI/Block/Block'
import Flex from '../../UI/Flex/Flex'
import Container from '../../UI/Container/Container'
import Button from '../../UI/Button/Button'
import { useSelector } from 'react-redux'
import starImage from '../../../assets/image/home/ratings/star.png'
import Widget from './Widget/Widget'
import CTA from '../CTA/CTA'

const Stars = () => {
  return (
    <StyledStars>
      <Star src={starImage} />
      <Star src={starImage} />
      <Star src={starImage} />
      <Star src={starImage} />
      <Star src={starImage} />
    </StyledStars>
  )
}

const Ratings = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <Block padding="100px 0px" height="auto">
      <CTA margin=" 0 0 75px 0 " />
      <Headline>{translation.home.ratings.headline}</Headline>
      <Container>
        <Flex gap="50px" margin="50px 0">
          <Row>
            <Rating>
              <Stars />
              <Text>{translation.home.ratings.list[0].text}</Text>
              <Name>{translation.home.ratings.list[0].name}</Name>
            </Rating>
            <Rating>
              <Stars />
              <Text>{translation.home.ratings.list[1].text}</Text>
              <Name>{translation.home.ratings.list[1].name}</Name>
            </Rating>
          </Row>
          <Row>
            <Rating>
              <Stars />
              <Text>{translation.home.ratings.list[2].text}</Text>
              <Name>{translation.home.ratings.list[2].name}</Name>
            </Rating>
            <Rating>
              <Stars />
              <Text>{translation.home.ratings.list[3].text}</Text>
              <Name>{translation.home.ratings.list[3].name}</Name>
            </Rating>
          </Row>
        </Flex>
      </Container>
      <Flex>
        {/* <GlobalRatingImage src={globalRating} /> */}
        <Widget />
      </Flex>
      <Flex margin="30px 0 0 0">
        <Button
          href="https://www.provenexpert.com/hypro-finance-gmbh"
          newTab={true}
          text={translation.home.ratings.cta}
        />
      </Flex>
      <CTA margin="50px 0  0 0" />
    </Block>
  )
}

export default Ratings
