import styled from 'styled-components'
import Text from '../../../UI/Text/Text'
import Link from '../../Link/Link'

export const StyledFooter = styled.div`
  padding: 50px;
  background-color: ${({ theme }) => theme.color.backgroundDark};
`

export const Column = styled.div`
  flex: 1 1 0px;
`

export const Subline = styled.div`
  color: ${({ theme }) => theme.color.font.highlightOnDark};
  font-size: ${({ theme }) => theme.size.sm};
`

export const Split = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: right;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
  }
`

export const Section = styled.div`
  width: 25%;
  text-align: right;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const Title = styled(Text)`
  font-size: ${({ theme }) => theme.size.sm};
  font-family: 'bold';
  margin-bottom: 15px;
  text-align: right;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin: 15px 0px;
    font-size: ${({ theme }) => theme.size.md};
  }
`

export const CustomLink = styled(Link)`
  font-size: ${({ theme }) => theme.size.sm};
  display: block;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.md};
  }
`
