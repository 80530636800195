import { useSelector } from 'react-redux'
import Block from '../../UI/Block/Block'
import Container from '../../UI/Container/Container'
import Image from '../../UI/Image/Image'
import {
  Headline,
  Text,
  Split,
  PointText,
  Point,
  SplitWrapper,
  Bulletpoints,
  Indicator,
} from './Dreams.Styled'
import woman from '../../../assets/image/home/dreams/woman.png'
import { getUUID } from '../../../utility'
import pointIcon from '../../../assets/image/home/dreams/pointIcon.png'
import CTA from '../CTA/CTA'

const Dreams = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <Block padding="100px 0" height="auto">
      <Headline>{translation.home.dreams.headline}</Headline>
      <Container>
        <SplitWrapper>
          <Split>
            <Image src={woman} width="100%" />
          </Split>
          <Split>
            <Bulletpoints>
              {translation.home.dreams.bulletpoints.map((p) => {
                return (
                  <Point key={getUUID()}>
                    <Indicator src={pointIcon} />
                    <PointText>{p}</PointText>
                  </Point>
                )
              })}
            </Bulletpoints>
          </Split>
        </SplitWrapper>
        <Text>{translation.home.dreams.text}</Text>
        <CTA margin="50px 0 0 0" />
      </Container>
    </Block>
  )
}

export default Dreams
