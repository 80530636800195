import styled from 'styled-components'

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
  text-align: center;
  margin-bottom: 25px;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  border-bottom: 10px solid ${({ theme }) => theme.color.background};
  &:last-child {
    border-bottom: none;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
    border-bottom: none;
  }
`

export const Cell = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundRedTransparent};
  height: 100px;
  flex: 1 1 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 10px solid ${({ theme }) => theme.color.background};
  &:last-child {
    border-right: none;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    border-bottom: 10px solid ${({ theme }) => theme.color.background};
    border-right: none;
    flex: auto;
    width: 50%;
  }
`

export const ImageWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  width: 80%;
  height: 80%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Image = styled.img`
  max-height: 80%;
  max-width: 80%;
`

export const Content = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundRedTransparent};
  border-bottom: 10px solid ${({ theme }) => theme.color.background};
  padding: 50px 0px;
  margin-top: 50vh;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    border-bottom: 10px solid ${({ theme }) => theme.color.background};
    margin-top: 0%;
  }
`
export const Text = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.md};
  }
`

export const Float = styled.div`
  position: static;
  top: 30vh;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 100%;
  }
`
