import styled from 'styled-components'
import Block from '../../UI/Block/Block'

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.size.lg};
  color: ${({ theme }) => theme.color.font.thirdary};
  font-family: 'bold';
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
`

export const Text = styled.div`
  font-size: ${({ theme }) => theme.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    text-align: right;
  }
`

export const SignatureImage = styled.img`
  height: 75px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 50px;
    margin-left: auto;
  }
`

export const Content = styled.div`
  top: 0px;
  width: 800px;
  height: 100%;
  display: flex;
  position: absolute;
  left: 50%;
  flex-wrap: wrap;
  align-content: center;
  gap: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    left: 0px;
    height: 100%;
    width: 100%;
    padding: 15px;
    align-content: flex-end;
  }
`

export const CustomBlock = styled(Block)`
  padding: 100px 0;
  height: 60vh;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-position: 20% center;
  }
`
