import { useSelector } from 'react-redux'
import Block from '../../UI/Block/Block'
import Flex from '../../UI/Flex/Flex'
import { Headline, Image, ImageWrapper, Row, Cell, Content, Text, Float } from './Partner.Styled'
import bg from '../../../assets/image/home/partner/bg.jpg'
import img1 from '../../../assets/image/home/partner/1.svg'
import img2 from '../../../assets/image/home/partner/2.svg'
import img3 from '../../../assets/image/home/partner/3.png'
import img4 from '../../../assets/image/home/partner/4.svg'
import img5 from '../../../assets/image/home/partner/5.svg'
import img6 from '../../../assets/image/home/partner/6.svg'
import img7 from '../../../assets/image/home/partner/7.svg'
import img8 from '../../../assets/image/home/partner/8.svg'
import Container from '../../UI/Container/Container'

const Partner = () => {
  const translation = useSelector((s) => s.translation)

  return (
    <Block
      margin="50px 0 0 0"
      backgroundImage={bg}
      height="auto"
      backgroundPosition={'center center'}
    >
      <Float>
        <Content>
          <Container>
            <Headline>{translation.home.partner.headline}</Headline>
            <Text>{translation.home.partner.text}</Text>
          </Container>
        </Content>
        <Flex wrap="wrap">
          <Row>
            <Cell>
              <ImageWrapper>
                <Image src={img1} />
              </ImageWrapper>
            </Cell>
            <Cell>
              <ImageWrapper>
                <Image src={img2} />
              </ImageWrapper>
            </Cell>
            <Cell>
              <ImageWrapper>
                <Image src={img3} />
              </ImageWrapper>
            </Cell>
            <Cell>
              <ImageWrapper>
                <Image src={img4} />
              </ImageWrapper>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <ImageWrapper>
                <Image src={img5} />
              </ImageWrapper>
            </Cell>
            <Cell>
              <ImageWrapper>
                <Image src={img6} />
              </ImageWrapper>
            </Cell>
            <Cell>
              <ImageWrapper>
                <Image src={img7} />
              </ImageWrapper>
            </Cell>
            <Cell>
              <ImageWrapper>
                <Image src={img8} />
              </ImageWrapper>
            </Cell>
          </Row>
        </Flex>
      </Float>
    </Block>
  )
}

export default Partner
