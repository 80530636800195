//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  home: {
    ctaFinLink: {
      text: 'Angebot anfordern',
    },
    cta: {
      text: 'Direkt anrufen oder einen Termin vereinbaren',
    },
    intro: {
      banner: 'Dein größter Wunsch ist ein Eigenheim?',
    },
    dreams: {
      headline: 'WIR VERWIRKLICHEN DEINE TRÄUME!',
      bulletpoints: [
        'Machbarkeitsprüfung in nur 24 Stunden',
        'transparenter Marktüberblick zu Top-Konditionen von über 450 Banken',
        'spezialisierte Ansprechpartner mit über 20 Jahren Expertise',
        'schnelle Entscheidungen durch digitale Prozesse und direkter Kontakt zu Kreditentscheider',
        'kostenlose Objektbewertung für Deine Traumimmobilie',
        'Finanzierungszertifikat für Dein Vorhaben',
      ],
      text: 'OHNE EXTRAKOSTEN - UNSERE BERATUNG IST FÜR DICH GEBÜHRENFREI!',
    },
    stepByStep: {
      headline: 'SCHRITT FÜR SCHRITT ZUR TRAUMIMMOBILIE',
      text1: 'Gemeinsam erarbeiten wir Dein individuelles Finanzierungskonzept.',
      text2:
        'Dabei berücksichtigen wir Deine Präferenzen und vergleichen verschiedene Konditionen und Fördermöglichkeiten am Markt, bis wir das passgenaue Finanzierungsmodell für Dich entwickelt haben.',
      text3:
        'So sind wir bei jedem Schritt an Deiner Seite – vom Erstkontakt über die Beratung bis hin zur Kreditzusage und Dein Einzug in Deine Traumimmobilie.',
    },
    process: {
      headline: 'PROZESSERKLÄRUNG IN 5 SCHRITTEN',
    },
    partner: {
      headline: '450 Finanzierungspartner',
      text: 'Ein Auszug unserer Finanzierungspartner',
    },
    ratings: {
      headline: 'DAS SAGEN UNSERE KUNDEN ÜBER UNS',
      list: [
        {
          text: '"Nicht nur die entspannte Kommunikation und das geschmeidige Auftreten - nein, auch die professionelle & zügige Arbeitsweise haben mich beeindruckt! Ihr schlagt perfekt die Brücke zwischen angenehmer legerer Zusammenarbeit und professioneller / qualitativ hochwertiger Finanzberatung."',
          name: '- Robert A.',
        },
        {
          text: '"...Ich selbst bin seit vielen Jahren in der Bank tätig. Ein solcher Service ist einzigartig. Es ist für mich immer noch unfassbar, dass mein sehr komplizierter Fall tatsächlich mit Erfolg durchgebracht werden konnte."',
          name: '- M.',
        },
        {
          text: '"Dies war bisher die beste Beratung und Betreuung die ich bisher hatte. Man hat sich viel Zeit genommen, all meine Fragen wurden ausführlich beantwortet, auf einzelnen Wünsche wurde immer Rücksicht genommen und das Bestmögliche aus unserer Situation heraus geholt, wir sind so glückliche Eigentümer nun."',
          name: '- Capar',
        },
        {
          text: '"Sie haben uns sehr geholfen eine sehr gute und für uns passende Finanzierung für unsere Immobilie zu finden. Sie waren immer erreichbar und ansprechbar und haben Ihre Erfahrungen und Kontakte eingebracht und unsere Anfrage, die viele Besonderheiten hatte super gut verstanden und an die Banken vermittelt."',
          name: '- Stephen M.',
        },
      ],
      cta: 'NOCH MEHR REZENSIONEN',
    },
    signature: {
      text: 'PROFITIERE VON UNSERER DIGITALEN SCHNELLIGKEIT MIT PERSÖNLICHEM SERVICE UND INDIVIDUELLEM ANSPRUCH.',
    },
    people: {
      headline: 'IHRE TRÄUMEVERWIRKLICHER',
      subheadline: 'NUR NOCH EINEN CLICK ENTFERNT!',
      mobile: 'Mobil: ',
      ctaHeadline:
        'Klicke auf den Button „Video Antragsprozess“ und wir erklären Dir in einem Kurzvideo, wie die Onlineantragsstrecke funktioniert.',
      videoCTA: 'Video Antragsprozess',
      secondCTA: 'Jetzt Angebot anfordern',
      list: [
        {
          name: 'Ahmet Akin',
          title: 'GESCHÄFTSFÜHRER',
          mail: 'ahmet.akin@hyprofinance.de',
          mobile: '0174 660 50 80',
          youtube: 'https://www.youtube.com/watch?v=tqtHqF4W3U8',
        },
        {
          name: 'Gamze Acar',
          title: 'FINANZIERUNGSBERATERIN',
          mail: 'gamze.acar@hyprofinance.de',
          mobile: '0172 297 84 05',
        },
        {
          name: 'Orhan Köse',
          title: 'FINANZIERUNGSBERATER',
          mail: 'orhan.koese@hyprofinance.de',
          mobile: '0176 636 276 96',
          youtube: 'https://www.youtube.com/watch?v=x7ZDPR7qXjA',
        },
        // {
        //   name: 'Yağmur Güner',
        //   title: 'FINANZIERUNGSBERATERIN',
        //   mail: 'yagmur.guener@hyprofinance.de',
        //   mobile: '0176 619 975 91',
        //   youtube: 'https://www.youtube.com/watch?v=V6u5JPLHKY4',
        // },
      ],
    },
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Datenschutz',
  },

  header: {
    partner: 'Der Partner',
    products: 'Produkte',
    businessFields: 'Geschäftsfelder',
    downloads: 'Downloads',
    career: 'Karriere',
    contact: 'Kontakt',
  },

  footer: {
    logoSubline: 'MAKING REAL ESTATE TRANSACTIONS PROFESSIONAL',
    general: {
      title: 'Unternehmen & Technologie',
      aboutUs: 'Über Uns',
      intelligentMatching: 'Intelligentes Matching',
      services: 'Leistungen & Kosten',
      faq: 'FAQ',
    },
    services: {
      title: 'Lösung für',
      bestandshalter: 'Bestandshalter, Investment & Asset Manager',
      pensionskassen: 'Pensionskassen, Fonds & Stiftungen',
      bautraeger: 'Bauträger & Projektentwickler',
      familyOffices: 'Family Offices & HNWI’s',
      privateEigentümer: 'Private Eigentümer & Verkaufsberater',
    },
    legal: {
      title: 'Rechtliches',
      imprint: 'Impressum',
      dataProtection: 'Datenschutz',
      agb: 'AGB',
    },
  },

  generic: {
    street: 'Markt 36',
    city: '41836 Hückelhoven',
    phone: '+49 30 74707-0',
    fax: '+49 30 74707-20',
    mail: 'info@sichert.com',
  },

  UI: {
    autocomplete: {
      searchError: 'Suche konnte nicht ausgeführt werden.',
      searchEmpty: 'Keine Ergebnisse',
    },
    dropdown: {
      emptySelection: '--- bitte wählen ---',
    },
  },
}

module.exports = {
  translation,
  langKey: 'DE',
}
