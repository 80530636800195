import styled from 'styled-components'

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.size.lg};
  color: ${({ theme }) => theme.color.font.thirdary};
  font-family: 'bold';
  text-align: center;
`
export const Subheadline = styled.div`
  font-size: ${({ theme }) => theme.size.lg};
  text-align: center;
`

export const Text = styled.div`
  font-size: ${({ theme }) => theme.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
`

export const SignatureImage = styled.img`
  height: 75px;
`

export const Content = styled.div`
  top: 0px;
  width: 800px;
  height: 100%;
  display: flex;
  position: absolute;
  left: 50%;
  flex-wrap: wrap;
  align-content: center;
  gap: 25px;
`

export const Image = styled.img`
  height: 200px;
  width: 200px;
  border-radius: 200px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 150px;
    width: 150px;
    border-radius: 150px;
  }
`

export const PeopleList = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 15px;
  gap: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    margin: 50px 0;
  }
`

export const Person = styled.div`
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const Name = styled.div`
  font-family: 'bold';
  margin-top: 15px;
  font-size: 30px;
`

export const Title = styled.div`
  text-transform: uppercase;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.md};
  }
`
export const Mail = styled.a`
  margin-top: 15px;
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.color.font.primary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.md};
  }
`
export const Mobile = styled.a`
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.color.font.primary};
  margin-top: 5px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.md};
  }
`

export const Highlight = styled.span`
  color: ${({ theme }) => theme.color.font.thirdary};
  font-size: ${({ theme }) => theme.size.md};
  &:hover {
    text-decoration: underline;
  }
`

export const YoutubeImage = styled.img`
  height: 50px;
`

export const Link = styled.a``

export const CTAHeadline = styled.div`
  text-align: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  font-family: bold;
  font-size: ${({ theme }) => theme.size.md};
  margin-bottom: 15px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`
