import { StyledLayout, CoreContent } from './Layout.Styled'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import ResponsiveState from './ResponsiveState/ResponsiveState'

const Layout = ({ children, className, addHeaderPadding = false, hideHeader = true }) => {
  return (
    <StyledLayout className={className}>
      <ResponsiveState />
      {!hideHeader && <Header />}
      <CoreContent addHeaderPadding={addHeaderPadding}>{children}</CoreContent>
      <Footer />
    </StyledLayout>
  )
}

export default Layout
