const _ = require('lodash')

const theme = {
  size: {
    xsm: '0.5rem',
    sm: '0.75rem',
    md: '1.1rem',
    lg: '2.25rem',
    xlg: '3rem',
  },
  color: {
    font: {
      primary: 'black',
      secondary: 'white',
      thirdary: 'rgb(156, 58, 65)',
      highlightOnLight: '#4CACC8',
      highlightOnDark: '#81E5EE',
      light: 'rgb(102,102,102)',
    },
    button: {
      background: 'rgb(156, 58, 65)',
      font: '#ffffff',
    },
    input: {
      background: '#ffffff',
      font: '#000000',
      border: '#ffffff',
    },
    background: '#ffffff',
    backgroundLight: 'rgb(225,229,234)',
    backgroundRed: 'rgb(156, 58, 65)',
    backgroundRedTransparent: 'rgba(156, 58, 65, 0.8)',
    border: {
      dark: '#000000',
      light: '#ffffff',
    },
    primary: '#118bdc',
    secondary: 'rgb(236,237,242)',
    danger: '#E74C3C',
  },
  breakpoint: {
    sm: '768px',
    md: '992px',
    lg: '1300px',
  },
  border: {
    radius: '7px',
  },
  shadow: '2px 2px 16px -1px rgba(0,0,0,0.48)',
  headerHeight: '60px',
  subMenuHeight: '140px',
  subMenuHintHeight: '60px',
}

const getTheme = (originTheme = {}) => _.merge({}, theme, originTheme)

module.exports = getTheme
